import React, { useEffect, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { createSearchParams, Link, useLocation } from "react-router-dom";
import Table from "../../lib/react-table/Table";
import moment from "moment";
import { apiSlice } from "../../store/apiSlice";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import ClaimModal, { iPendingClaims } from "../../components/global/ClaimModal";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import { Colors, TableText } from "../global/TableText";
import ContextMenu from "../global/ContextMenu";
import { TableTextNormal } from "../global/TableTextNormal";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const EventAttractionsTable: React.FC = () => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { data: claimsTableData, isFetching } =
    apiSlice.useGetEventApprovalAttractionsQuery();
  const data = React.useMemo<AttractionDetails[]>(
    () => claimsTableData?.results ?? ([] as AttractionDetails[]),
    [claimsTableData]
  );

  const [
    updatePendingApproval,
    {
      data: pendingApprovalData,
      isLoading: isPendingApprovalLoading,
      isError,
      isSuccess: isUpdateSuccess,
    },
  ] = apiSlice.useUpdatePendingApprovalMutation();

  const [getShowClaimModal, setShowClaimModal] = useState<iPendingClaims>({
    alreadyClaimed: false,
    show: false,
    id: "",
    status: null,
    primaryImage: "/assets/amp/dowtk-mono.png",
    name: "",
    address: "",
  });

  const schema = z.object({
    note: z.string().min(1, "Reason for claim denied is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    setValue,
    getValues,
  } = useForm<iPendingClaims>({
    resolver: async (data, context, options) => {
      return zodResolver(schema)(data, context, options);
    },
    defaultValues: {
      note: "",
    },
  });

  const onSubmit: SubmitHandler<iPendingClaims> = async ({
    id,
    status,
    note,
  }) => {
    await updatePendingApproval({
      id: id || getShowClaimModal.id,
      payload: {
        status: status || getShowClaimModal.status,
        note: getValues("note"),
      },
    });
    restartAnimation();
  };

  const columns = React.useMemo<Column<AttractionDetails>[]>(
    () => [
      {
        Header: "Attraction Name",
        accessor: "name",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <Link
                to={`/attractions/${
                  original?.venue?.short_id
                }?${createSearchParams({
                  "claim-id": original?.id?.toString() ?? "",
                }).toString()}`}
                state={{
                  from: "/dashboard/pending-approval" + location.search,
                  claim: {
                    created_by_user: original.created_by_user,
                    claimed: original.claimed,
                    id: original.id,
                    operator: original.operator,
                    created_at: original.created_at,
                    new: true
                  },
                }}
                className="truncate block font-bold text-black font-roboto-flex text-sm w-full xs:w-60 xl:w-auto"
              >
                {original.venue.name}
              </Link>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          if (a.original.venue["name"] > b.original.venue["name"]) return 1;
          if (b.original.venue["name"] > a.original.venue["name"]) return -1;
          return 0;
        },
        width: "37.78%",
        minWidth: 225,
      },
      {
        Header: "Type",
        accessor: "listing_package",
        sortType: (a: any, b: any) => {
          const priority: Record<any, number> = {
            "ENHANCED": 1,
            "PREMIUM": 2,
            "STANDARD": 3,
            "BASIC": 4
          };
      
          const aValue = a.original.venue?.listing_package || "";
          const bValue = b.original.venue?.listing_package || "";
      
          const aRank = priority[aValue] || 5;
          const bRank = priority[bValue] || 5;
      
          return aRank - bRank;
        },
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          const color: Record<string, Colors> = {
            BASIC: "default",
            STANDARD: "blue",
            PREMIUM: "orange",
            ENHANCED: "purple",
          };
          return (
            <>
              <TableText
                className="min-w-[8rem]"
                color={color[original.venue.listing_package]}
              >
                {original.venue.listing_package[0] +
                  original.venue.listing_package.toLowerCase().slice(1)}
              </TableText>
            </>
          );
        },
        width: "8.44%",
        id: "listing_package",
      },
      {
        Header: "Address",
        accessor: (data) => {
          return (
            <>
              {data.venue.city && (
                <TableText className="mb-1">{data.venue.city}</TableText>
              )}
              <TableText>{data.venue.postcode}</TableText>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          if (
            (a.original.venue.postcode ?? "").toString() >
            (b.original.venue.postcode ?? "").toString()
          )
            return 1;
          if (
            (b.original.venue.postcode ?? "").toString() >
            (a.original.venue.postcode ?? "").toString()
          )
            return -1;
          return 0;
        },
        width: "16.7%",
        minWidth: 110,
      },
      {
        Header: "Created By",
        accessor: "created_by_user",
        sortType: (a: any, b: any) => {
          const previousRow = a.original.created_at;
          const nextRow = b.original.created_at;

          if (previousRow < nextRow) {
            return 1;
          } else {
            return -1;
          }
        },
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <TableTextNormal>
                {moment(original.created_at).format("DD MMM YYYY")}
              </TableTextNormal>
            </>
          );
        },
        width: "11%",
      },
      {
        Header: "Claimed By",
        accessor: (data) => {
          return (
            <>
              <div className="w-full grid grid-cols-2 gap-2 xl:block xl:min-w-[8rem]">
                <TableText className="truncate mb-1">
                  {
                    data.created_by_user?.first_name || data.created_by_user?.last_name ? <>
                    {data.created_by_user?.first_name}{" "}
                    {data.created_by_user?.last_name}
                    </> : '-'
                  }
                </TableText>
                <TableText className="truncate">
                  {data.operator ? data.operator.title : '-'}
                </TableText>
              </div>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          if (
            a.original.operator.title.toString().toLowerCase() >
            b.original.operator.title.toString().toLowerCase()
          )
            return 1;
          if (
            b.original.operator.title.toString().toLowerCase() >
            a.original.operator.title.toString().toLowerCase()
          )
            return -1;
          return 0;
        },
        width: "14.14%",
      },
      {
        Header: "email",
        accessor: (data) => {
          return <>{data.created_by_user.email}</>;
        },
        width: "0%",
      },
      {
        Header: "Activity Type",
        accessor: (data) => data.venue.listing_type,
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          const labels: Record<ActivityType, string> = {
            attraction: "Attraction",
            club: "Club",
            event: "Event",
            festival: "Festival",
          };
          return (
            <>
              <TableTextNormal>
                {labels?.[original.venue.listing_type as ActivityType] ?? ""}
              </TableTextNormal>
            </>
          );
        },
        width: "0%",
        id: "listing_type",
      },
      {
        Header: "Duration",
        accessor: (data) => data.venue.duration_type,
        id: 'duration_type',
        width: "0%",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          const labels: Record<DurationType, string> = {
            permanent: 'Permanent',
            temporary: 'Temporary'
          };
          return (
            <>
              <TableTextNormal
                className="min-w-[6rem]"
              >
                {labels?.[original.venue.duration_type as DurationType] ?? ''}
              </TableTextNormal>
            </>
          );
        },
      },
      {
        Header: "website",
        accessor: (data) => {
          return <>{data.venue.website}</>;
        },
        width: "0%",
      },
      {
        Header: " ",
        accessor: (data) => {
          return (
            <div className="flex justify-between">
              <ContextMenu
                options={[
                  {
                    name: "View",
                    linkProps: {
                      to: `/attractions/${
                        data?.venue?.short_id
                      }?${createSearchParams({
                        "claim-id": data?.id?.toString() ?? "",
                      }).toString()}`,
                      state: {
                        from: "/dashboard/pending-approval" + location.search,
                        claim: {
                          created_by_user: data.created_by_user,
                          claimed: data.claimed,
                          id: data.id,
                          operator: data.operator,
                          created_at: data.created_at,
                          new: true
                        },
                      },
                    },
                  },
                ]}
              />
            </div>
          );
        },
        width: "3.5%",
        disableSortBy: true,
      },
    ],
    [isPendingApprovalLoading]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable<AttractionDetails>(
    {
      columns,
      data,
      disableSortRemove: true,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        hiddenColumns: ["email", "website"],
        sortBy: [{ id: "duration_type", desc: false }],
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<AttractionDetails>;

  useEffect(() => {
    isUpdateSuccess &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          pendingType: "new_attraction",
          type: "SUCCESS",
          message: "Your changes have been saved",
          attractionApprovalStatus: pendingApprovalData?.claims.reduce(
            (a: any, b: any) => (a.resolved_at > b.resolved_at ? a : b)
          ).status,
          attractionName: pendingApprovalData?.name,
          attractionAddress: pendingApprovalData?.postcode,
        })
      );
    setShowClaimModal({
      alreadyClaimed: false,
      show: false,
      id: "",
      status: null,
      primaryImage: "",
      name: "",
      address: "",
    });
    setValue("note", "");
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot save changes",
        })
      );
  }, [isError]);

  return (
    <>
      <div id="pendingApprovalTable">
        <Table<AttractionDetails>
          // <table id>
          tableId="pendingApprovalTable"
          // <table className>
          tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
          // <thead className>
          tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
          // <tbody className>
          tableTbodyClassNames={`relative ${
            page.length === 0 ? "h-[60px]" : ""
          }`}
          // Mobile <tbody className>
          tableTbodyMobileClassNames="block"
          // <tbody><tr className>
          tableTbodyTrClassNames="h-1 last-of-type:hidden"
          // Empty <tbody><tr className>
          tableTbodyTrEmptyMobileClassNames="border-none"
          // Mobile <tbody><tr className>
          tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative border-2 border-cs-off-white font-sm font-normal text-black rounded-xl p-3 bg-white"
          // <tbody><tr><td className>
          tableTbodyTrTdClassNames="relative bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
          // Empty <tbody><tr><td className>
          tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
          // Mobile <tbody><tr><td className>
          tableTbodyTrEmptyTdMobileClassNames="h-2"
          // Hide Columns On Mobile
          hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
          // react-table props
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          pages={claimsTableData?.total}
          prepareRow={prepareRow}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          isFetching={isFetching}
        />
      </div>

      <ClaimModal
        className={`w-[787px] ${
          !getShowClaimModal.showApprove && "min-h-[400px] px-10 xl:px-0"
        }`}
        setValue={setValue}
        open={getShowClaimModal}
        setOpen={setShowClaimModal}
      >
        <h3 className="text-2xl font-bold text-cs-gray">
          {getShowClaimModal.showApprove ? "Approve?" : "Deny claim for"}
        </h3>
        <div className="mt-10 border-cs-lt-gray border-cs-1 bg-white flex w-full rounded-lg overflow-hidden min-h-cs-100">
          <div
            className={`w-1/4 max-w-cs-112 bg-contain bg-no-repeat bg-cs-off-white bg-center ${
              getShowClaimModal.primaryImage == "/assets/amp/dowtk-mono.png"
                ? "bg-[length:80%]"
                : "bg-cover"
            }`}
            style={{
              backgroundImage: `url(${getShowClaimModal.primaryImage})`,
            }}
          ></div>
          <div className="p-5 w-3/4 flex flex-col justify-center">
            <h4 className="font-bold text-cs-pink text-xl overflow-hidden w-full whitespace-nowrap text-ellipsis">
              {getShowClaimModal.name}
            </h4>
            <span className="font-normal text-cs-gray block overflow-hidden w-full whitespace-nowrap text-ellipsis">
              {getShowClaimModal.address}
            </span>
          </div>
        </div>

        {getShowClaimModal.showApprove ? (
          <>
            <p className="font-normal my-4 text-base text-cs-gray text-center">
              This will transfer ownership of the attraction to{" "}
              <h4 className="inline-block text-cs-pink text-xl font-bold">
                {getShowClaimModal.claimedBy}
              </h4>
              . <h3 className="text-2xl font-bold mt-7">Are you sure?</h3>
            </p>

            <div className="flex flex-row mt-4">
              <button
                type="button"
                className="h-cs-55 rounded-2xl mr-14 py-2 px-6 text-sm text-white bg-cs-pink border-2 border-cs-pink hover:bg-white hover:text-cs-pink"
                onClick={() => {
                  onSubmit({
                    alreadyClaimed: false,
                    id: getShowClaimModal.id,
                    status: "APPROVED",
                  });
                  setShowClaimModal({
                    ...getShowClaimModal,
                    showApprove: false,
                  });
                }}
              >
                Yes, approve
              </button>

              <button
                type="button"
                className="text-cs-gray py-2 px-6 text-sm hover:text-cs-pink hover:underline"
                onClick={() => {
                  setShowClaimModal({
                    ...getShowClaimModal,
                    showApprove: false,
                  });
                }}
              >
                No
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="font-normal my-7 text-base text-cs-gray text-center">
              Enter a reason for denying this claim. This text will be sent
              directly to the claimer via email, so check spelling & grammar
            </p>

            <form
              className="w-full h-full flex flex-col"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input {...register("id")} value={getShowClaimModal.id} hidden />
              <fieldset className="flex flex-col w-full">
                <label className="text-cs-gray font-normal flex">
                  Why was this claim denied?
                </label>
                <textarea
                  {...register("note")}
                  className="text-cs-gray text-md w-full p-3 mt-2 border-cs-lt-gray border-cs-1 outline-cs-pink rounded-sm h-32"
                ></textarea>
                {errors.note && (
                  <div className="text-cs-red flex items-center mt-2 mb-5">
                    <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                    {errors.note?.message}
                  </div>
                )}
              </fieldset>

              <button
                type="submit"
                disabled={isPendingApprovalLoading}
                className={`mx-auto mt-14 mb-5 leading-none rounded-full h-cs-55 w-full py-2 px-12 text-white text-md border-cs-1 font-bold shadow-cs-button-backdrop hover:text-cs-pink hover:outline-cs-pink md:w-auto ${
                  isSubmitted && !isValid && Object.keys(errors).length != 0
                    ? "bg-cs-red border-cs-red hover:bg-white"
                    : isPendingApprovalLoading
                    ? "bg-cs-bright-lt-gray border-cs-bright-lt-gray text-opacity-50 !hover:bg-cs-bright-lt-gray hover:border-cs-bright-lt-gray hover:text-opacity-50 hover:text-white"
                    : "bg-cs-pink border-cs-pink hover:bg-white"
                }`}
              >
                Deny & send to claimer
              </button>

              {isSubmitted && !isValid && Object.keys(errors).length != 0 && (
                <div className="text-cs-red flex items-center self-center justify-center mt-3">
                  <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                  <span className="flex-1">
                    Please fix the errors indicated above to continue
                  </span>
                </div>
              )}
            </form>
          </>
        )}
      </ClaimModal>
    </>
  );
};

export default EventAttractionsTable;
