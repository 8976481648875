import { Fragment, MouseEventHandler } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ContextMenuButtons({
  options,
}: {
  options: { name: string, action: MouseEventHandler<HTMLButtonElement>, bgRed?: boolean }[];
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full">
          <button className="ml-auto">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0625 15.0625C15.3333 14.7917 15.6458 14.6562 16 14.6562C16.3542 14.6562 16.6667 14.7917 16.9375 15.0625C17.2083 15.3333 17.3438 15.6458 17.3438 16C17.3438 16.3542 17.2083 16.6667 16.9375 16.9375C16.6667 17.2083 16.3542 17.3438 16 17.3438C15.6458 17.3438 15.3333 17.2083 15.0625 16.9375C14.7917 16.6667 14.6562 16.3542 14.6562 16C14.6562 15.6458 14.7917 15.3333 15.0625 15.0625ZM19.0625 15.0625C19.3333 14.7917 19.6458 14.6562 20 14.6562C20.3542 14.6562 20.6667 14.7917 20.9375 15.0625C21.2083 15.3333 21.3438 15.6458 21.3438 16C21.3438 16.3542 21.2083 16.6667 20.9375 16.9375C20.6667 17.2083 20.3542 17.3438 20 17.3438C19.6458 17.3438 19.3333 17.2083 19.0625 16.9375C18.7917 16.6667 18.6562 16.3542 18.6562 16C18.6562 15.6458 18.7917 15.3333 19.0625 15.0625ZM11.0625 15.0625C11.3333 14.7917 11.6458 14.6562 12 14.6562C12.3542 14.6562 12.6667 14.7917 12.9375 15.0625C13.2083 15.3333 13.3438 15.6458 13.3438 16C13.3438 16.3542 13.2083 16.6667 12.9375 16.9375C12.6667 17.2083 12.3542 17.3438 12 17.3438C11.6458 17.3438 11.3333 17.2083 11.0625 16.9375C10.7917 16.6667 10.6562 16.3542 10.6562 16C10.6562 15.6458 10.7917 15.3333 11.0625 15.0625Z"
                fill="black"
              ></path>
            </svg>
          </button>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute right-auto xl:right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div className="py-1">
            {options.map((o) => (
              <Menu.Item>
                {({ active }) => (
                  <button
                  type="button"
                    className={classNames(
                      active ? "bg-gray-100 text-black" : "text-gray-700",
                      "block px-4 py-2 text-sm font-roboto-flex w-full text-left",
                      (o.bgRed ? 'bg-red-500 text-white rounded-b-md' : '')
                    )}
                    onClick={o.action}
                  >
                    {o.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
