import React from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { createSearchParams, Link, useLocation } from "react-router-dom";
import Table from "../../lib/react-table/Table";
import moment from "moment";
import { apiSlice } from "../../store/apiSlice";
import { EmailVerificationButton } from "../global/www/modals/VerifyEmailModal";
import { Colors, TableText } from "../global/TableText";
import { TableTextNormal } from "../global/TableTextNormal";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const DeniedAttractionsTable: React.FC = () => {
  const location = useLocation();
  const { data: claimsTableData, isFetching } =
    apiSlice.useGetDeniedApprovalAttractionsQuery();
  const data = React.useMemo<AttractionDetails[]>(
    () => claimsTableData?.results ?? ([] as AttractionDetails[]),
    [claimsTableData]
  );

  const columns = React.useMemo<Column<AttractionDetails>[]>(
    () => [
      {
        Header: "Attraction Name",
        accessor: "name",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <Link
                to={`/attractions/${
                  original?.venue?.short_id
                }?${createSearchParams({
                  "claim-id": original?.id?.toString() ?? "",
                }).toString()}`}
                state={{
                  from: "/dashboard/pending-approval" + location.search,
                  claim: {
                    created_by_user: original.created_by_user,
                    claimed: original.claimed,
                    id: original.id,
                    operator: original.operator,
                    created_at: original.created_at,
                  },
                }}
                className="whitespace-nowrap text-ellipsis overflow-hidden block font-bold text-black font-roboto-flex text-sm w-full xs:w-60 xl:w-auto"
              >
                {original.venue.name}
              </Link>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          if (a.original.venue["name"] > b.original.venue["name"]) return 1;
          if (b.original.venue["name"] > a.original.venue["name"]) return -1;
          return 0;
        },
        width: "41.28%",
        minWidth: 225,
      },
      {
        Header: "Type",
        accessor: "listing_package",
        sortType: (a: any, b: any) => {
          const priority: Record<any, number> = {
            "ENHANCED": 1,
            "PREMIUM": 2,
            "STANDARD": 3,
            "BASIC": 4
          };
      
          const aValue = a.original.venue?.listing_package || "";
          const bValue = b.original.venue?.listing_package || "";
      
          const aRank = priority[aValue] || 5;
          const bRank = priority[bValue] || 5;
      
          return aRank - bRank;
        },
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          const color: Record<string, Colors> = {
            BASIC: "default",
            STANDARD: "blue",
            PREMIUM: "orange",
            ENHANCED: "purple",
          };
          return (
            <>
              <TableText
                className="min-w-[8rem]"
                color={color[original.venue.listing_package]}
              >
                {original.venue.listing_package[0] + original.venue.listing_package.toLowerCase().slice(1,)}
              </TableText>
            </>
          );
        },
        width: "8.44%",
        id: "listing_package",
      },
      {
        Header: "Address",
        accessor: (data) => {
          return (
            <>
              {data.venue.city && (
                <TableText className="mb-1">{data.venue.city}</TableText>
              )}
              <TableText>{data.venue.postcode}</TableText>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          if (
            (a.original.venue.postcode ?? "").toString() >
            (b.original.venue.postcode ?? "").toString()
          )
            return 1;
          if (
            (b.original.venue.postcode ?? "").toString() >
            (a.original.venue.postcode ?? "").toString()
          )
            return -1;
          return 0;
        },
        width: "16.7%",
        minWidth: 110,
      },
      {
        Header: "Created At",
        accessor: "created_by_user",
        sortType: (a: any, b: any) => {
          const previousRow = a.original.created_at;
          const nextRow = b.original.created_at;

          if (previousRow < nextRow) {
            return 1;
          } else {
            return -1;
          }
        },
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <TableTextNormal>
                {moment(original.created_at).format("DD MMM YYYY")}
              </TableTextNormal>
            </>
          );
        },
        width: "11%",
      },
      {
        Header: "Claimed By",
        accessor: (data) => {
          return (
            <>
            <div className="w-full grid grid-cols-2 gap-2 xl:block xl:min-w-[9rem]">
                <TableText className="truncate mb-1">
                  {
                    data.created_by_user?.first_name || data.created_by_user?.last_name ? <>
                    {data.created_by_user?.first_name}{" "}
                    {data.created_by_user?.last_name}
                    </> : '-'
                  }
                </TableText>
                <TableText className="truncate">
                  {data.operator ? data.operator.title : '-'}
                </TableText>
              </div>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          if (
            a.original.operator.title.toString().toLowerCase() >
            b.original.operator.title.toString().toLowerCase()
          )
            return 1;
          if (
            b.original.operator.title.toString().toLowerCase() >
            a.original.operator.title.toString().toLowerCase()
          )
            return -1;
          return 0;
        },
        width: "14.14%",
      },
      {
        Header: "Verified",
        width: "8.44%",
        sortType: (a: any, b: any) => {
          if (a.original.created_by_user?.email_verified === null) return 1;
          if (b.original.created_by_user?.email_verified === null) return -1;
          return 0;
        },
        accessor: (data) => {
          return (
            <>
              <TableText
                color={data.created_by_user?.email_verified ? "lime" : "default"}
              >
                {data.created_by_user?.email_verified
                  ? "Verified"
                  : "Unverified"}
              </TableText>

              {!data.created_by_user?.email_verified && (
                <EmailVerificationButton
                  className="mt-2 text-black text-xs text-center font-bold block w-[100px]"
                  label={"Resend "}
                  email={data.created_by_user?.email}
                />
              )}
            </>
          );
        },
      },
     
      {
        Header: "email",
        accessor: (data) => {
          return <>{data.created_by_user?.email}</>;
        },
        width: "0%",
      },
      {
        Header: "website",
        accessor: (data) => {
          return <>{data.venue.website}</>;
        },
        width: "0%",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable<AttractionDetails>(
    {
      columns,
      data,
      disableSortRemove: true,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        hiddenColumns: ["email", "website"],
        sortBy: [{ id: "created_by_user", desc: true }],
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<AttractionDetails>;

  return (
    <>
      <div id="pendingApprovalTable">
        <Table<AttractionDetails>
          // <table id>
          tableId="pendingApprovalTable"
          // <table className>
          tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
          // <thead className>
          tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
          // <tbody className>
          tableTbodyClassNames={`relative ${
            page.length === 0 ? "h-[60px]" : ""
          }`}
          // Mobile <tbody className>
          tableTbodyMobileClassNames="block"
          // <tbody><tr className>
          tableTbodyTrClassNames="h-1 last-of-type:hidden"
          // Empty <tbody><tr className>
          tableTbodyTrEmptyMobileClassNames="border-none"
          // Mobile <tbody><tr className>
          tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative border-2 border-cs-off-white font-sm font-normal text-black rounded-xl p-3 bg-white"
          // <tbody><tr><td className>
          tableTbodyTrTdClassNames="relative bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
          // Empty <tbody><tr><td className>
          tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
          // Mobile <tbody><tr><td className>
          tableTbodyTrEmptyTdMobileClassNames="h-2"
          // Hide Columns On Mobile
          hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
          // react-table props
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          pages={claimsTableData?.total}
          prepareRow={prepareRow}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          isFetching={isFetching}
        />
      </div>
    </>
  );
};

export default DeniedAttractionsTable;
