import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";
import AttractionTickets from "../attractions/AttractionTickets";
import Onboarding from "./Onboarding";
import { useEffect } from "react";

export function DashboardTicket() {
  const { user } = useAppSelector((state) => state.global);
  const { attractionId } = useParams();

  const navigate = useNavigate()

  useEffect(() => {
    if (user?.role === 'ATTRACTION_MANAGER') {
      navigate(`/attractions/${attractionId}/ticketing/scan`)
    }
  }, [user?.role, attractionId, navigate])

  return (!!user?.ticketOnboardingUser || user?.role === 'ADMIN') ? <AttractionTickets /> : <Onboarding />;
}
