import React, { useEffect, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../../lib/react-table/Table";
import Footer from "../../../components/global/amp/Footer";
import { apiSlice } from "../../../store/apiSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Button } from "../../../components/global/Button";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { TableTextNormal } from "../../../components/global/TableTextNormal";
import ContextMenuButtons from "../../../components/global/ContextMenuButtons";
import { SearchField } from "../../../components/global/SearchField";
import { providers } from "./AdminTicketingReporting";
import Select from "react-select";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import { DropDownIndicator } from "../attractions/AttractionOpeningTimes";
import { components } from "react-select";
import { globalActions } from "../../../store/globalSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };


const integrations = [
  "with_attraction",
  "with_us",
  "with_provider",
  "completed",
] as const;
const integrationOptions: {
  label: string,
  value: typeof integrations[number]
}[] = [
  {
    label: 'With Attraction',
    value: 'with_attraction',

  },
  {
    label: 'With us',
    value: 'with_us',
  },
  {
    label: 'With provider',
    value: 'with_provider',
  },
  {
    label: 'completed',
    value: 'completed',
  }
]
const TicketMappings: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector((state: any) => state.global);
  const [getFilter, setFilter] = useState<"active" | "future" | "archive">(
    "active"
  );

  const providerOptions = providers.map((p) => ({
    value: p,
    label: p,
  }));
  const activeOptions = [
    {
      value: "active",
      label: "Active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];
  const [providerFilter, setProviderFilter] = useState("");
  const [integrationFilter, setIntegrationFilter] = useState("");
  const [venueId, setVenueId] = useState("");
  const [activeFilter, setActiveFilter] = useState("");

  const prevFilter = useRef(getFilter);
  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("venue");
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");

  const prevQuerySearch = useRef(getQuerySearchQuery);
  const { data: tableData, isFetching } = apiSlice.useGetTicketMappingsQuery({
    pageSize: getQueryPageSize,
    filter: getFilter,
    query: getQuerySearchQuery,
    venue_id: venueId || "",
    provider: providerFilter || "",
    integration: integrationFilter || "",
    status: activeFilter || "",
    sortBy: getQuerySortBy,
    sortDesc: getQuerySortDesc,
    page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
  });

  const [deleteTicketMapping] = apiSlice.useDeleteTicketMappingMutation();
  const [toggleTicketMappingStatus] =
    apiSlice.useToggleTicketMappingStatusMutation();

  const data = React.useMemo<any>(() => tableData?.results ?? [], [tableData]);
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const columns = React.useMemo<Column<TicketMapping>[]>(
    () => [
      {
        Header: "Venue Name",
        accessor: "venue",
        width: "40%",
        Cell: ({ row: { original } }: CellProps<TicketMapping>) => {
          return (
            <>
              <button type="button" className="block w-full cursor-pointer text-left" onClick={() => {
                    return navigate(`/ticket-mappings/${original.id}`);
              }}>
                <TableTextNormal className="w-full">
                  {original.venue?.name || "-"}
                </TableTextNormal>
              </button>
            </>
          );
        },
      },

      {
        Header: "Ticket Name",
        accessor: "name",
        width: "35%",
        Cell: ({ row: { original } }: CellProps<TicketMapping>) => {
          return (
            <>
              <div className="w-full">
                <TableTextNormal className="w-full max-w-[220px] truncate hover:overflow-visible hover:max-w-none">
                  {original.name || "-"}
                </TableTextNormal>
              </div>
            </>
          );
        },
      },
      {
        Header: "Provider",
        accessor: "provider",
        width: "10%",
        Cell: ({ row: { original } }: CellProps<TicketMapping>) => {
          return (
            <>
              <div className="w-full">
                <TableTextNormal className="w-full">
                  {original.provider || "-"}
                </TableTextNormal>
              </div>
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "is_active",
        width: "18%",
        Cell: ({ row: { original } }: CellProps<TicketMapping>) => {
          const isIntegerated =  original.integration_status === 'completed';
          const isActive =
            original.is_active &&
            original.is_available &&
            isIntegerated
          const nonAvailableActive =
            original.is_active &&
            !original.is_available &&
            isIntegerated;
          const isPending = !isIntegerated;
          return (
            <>
              <div className="w-full">
                <TableTextNormal className="w-full">
                  {(isActive
                    ? "Active"
                    : nonAvailableActive
                    ? 'Active - No availability'
                    : isPending
                    ? `Pending ${original.integration_status ? `- ${original.integration_status}` : '- No integration Status'}`
                    : "") || "-"}
                </TableTextNormal>
              </div>
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<TicketMapping>) => {
          const isActive = original.is_active && original.is_available && original.integration_status === 'completed';
          return (
            <ContextMenuButtons
              options={[
                {
                  name: "View / Edit",
                  action: async () => {
                    return navigate(`/ticket-mappings/${original.id}`);
                  },
                },
                {
                  name: "Link",
                  action: async () => {
                    openLinkInNewTab(`https://www.dayoutwiththekids.co.uk/attractions/${original.venue?.short_id}`);
                  },
                },
                {
                  name: isActive ? "Disable" : "Enable",
                  action: async () => {
                    if (window.confirm(`Are you sure you want to ${isActive ? 'disable' : 'enable'} this?`)) {
                      await toggleTicketMappingStatus({ id: original.id! });
                      dispatch(
                        globalActions.setToastNotifcation({
                          ...toastNotification,
                          pendingType: "claim",
                          type: "SUCCESS",
                          message: `Successfuly ${isActive ? "disabled" : "enabled"}`,
                        })
                      );
                      restartAnimation();
                    }
                  },
                },  
                {
                  name: "Delete",
                  action: async () => {
                    if (window.confirm('Are sure you want to delete this?')) {
                      const result = await deleteTicketMapping({ id: original.id! });

                      if ('error' in result) {
                        dispatch(
                          globalActions.setToastNotifcation({
                            ...toastNotification,
                            pendingType: "claim",
                            type: "ERROR",
                            message: (result.error as any).data.message || "Failed to delete ticket mapping",
                          })
                        );
                        restartAnimation();
                      } else {
                        dispatch(
                          globalActions.setToastNotifcation({
                            ...toastNotification,
                            pendingType: "claim",
                            type: "SUCCESS",
                            message: "Successfuly deleted the ticket and availabilities.",
                          })
                        );
                        restartAnimation();
                      }
                    }
                  },
                  bgRed: true
                },
              ]}
            />
          );
        },
        disableGlobalFilter: true,
        width: "5%",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<TicketMapping>(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      pageCount: tableData?.total,
      disableSortRemove: true,
      initialState: {
        hiddenColumns: user?.role !== "ADMIN" ? ["operator", "role"] : [],
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<TicketMapping>;

  useEffect(() => {
    if (prevFilter.current !== getFilter) {
      setQueryPage("0");
      gotoPage(0);

      prevFilter.current = getFilter;
    }
  }, [getFilter, gotoPage]);

  useEffect(() => {
    document.title = "Ticket Mappings | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery]);
  useEffect(() => {
    if (!isFetching) {
      setSearchParams(
        {
          pageSize: pageSize.toString(),
          page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");
      setQuerySortBy(sortBy[0].id.toLowerCase());
      setQuerySortDesc(sortBy[0].desc!.toString());
    }
  }, [
    pageSize,
    pageIndex,
    sortBy[0].id,
    globalFilter,
    sortBy[0].desc,
    isFetching,
  ]);

  useEffect(() => {
    if (!user?.email_verified && user?.role !== "ADMIN") {
      navigate("/dashboard");
    }
  }, []);

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  const { data: venuesWithTickets } = apiSlice.useGetVenuesWithTicketsQuery();
  const venuesOptions =
    venuesWithTickets?.map((v) => ({
      value: v.id,
      label: v.name as string,
    })).sort((a, b) => a.label?.localeCompare(b.label)) || [];

    useEffect(() => {
      gotoPage(0)
    }, [integrationFilter, providerFilter, venueId, activeFilter, gotoPage])

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <DashboardHeaderContainer slim={true}>
        <div className="flex items-center flex-wrap gap-y-2 w-[calc(100%-28px)] xl:w-full">
          <h1 className="font-extrabold text-xl text-black">Ticket Mappings</h1>

          <SearchField
            parentClassName="max-w-full w-[437px] ml-auto"
            defaultValue={searchParams.get("query") ?? ""}
            onChange={handleFilterInputChange}
            placeholder="Search"
          />

          <Link to="/ticket-mappings/add" className="ml-2">
            <Button>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8124 8.66683H8.63533V11.8439C8.63533 12.0124 8.56839 12.1741 8.44923 12.2932C8.33006 12.4124 8.16844 12.4793 7.99992 12.4793C7.8314 12.4793 7.66978 12.4124 7.55061 12.2932C7.43145 12.1741 7.3645 12.0124 7.3645 11.8439V8.66683H4.18742C4.0189 8.66683 3.85728 8.59988 3.73811 8.48072C3.61895 8.36156 3.552 8.19994 3.552 8.03141C3.552 7.86289 3.61895 7.70127 3.73811 7.58211C3.85728 7.46294 4.0189 7.396 4.18742 7.396H7.3645V4.21891C7.3645 4.05039 7.43145 3.88877 7.55061 3.76961C7.66978 3.65044 7.8314 3.5835 7.99992 3.5835C8.16844 3.5835 8.33006 3.65044 8.44923 3.76961C8.56839 3.88877 8.63533 4.05039 8.63533 4.21891V7.396H11.8124C11.9809 7.396 12.1426 7.46294 12.2617 7.58211C12.3809 7.70127 12.4478 7.86289 12.4478 8.03141C12.4478 8.19994 12.3809 8.36156 12.2617 8.48072C12.1426 8.59988 11.9809 8.66683 11.8124 8.66683Z"
                  fill="white"
                />
              </svg>
              Add Mapping
            </Button>
          </Link>
        </div>
      </DashboardHeaderContainer>

      <DashboardContainer>
        <div className="px-5 xl:px-[20px] flex flex-wrap xl:flex-row flex-col gap-y-2 justify-between items-center">
          <div className="flex flex-wrap gap-2.5 items-center justify-end w-full">
            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Venue
              </label>
              <Select
                value={venuesOptions.find((o) => o.value === venueId)}
                isClearable={true}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: DropDownIndicator,
                  IndicatorSeparator: () => null,
                  ClearIndicator: (props) => (
                    <components.ClearIndicator {...props}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </components.ClearIndicator>
                  ),
                }}
                options={venuesOptions}
                isSearchable={true}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",
                    minWidth: 390,

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      height: "auto !important",
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CFDBD5"
                      : "1px solid #CFDBD5",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CFDBD5"
                        : "1px solid #CFDBD5",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 40,
                    minWidth: 190,
                    borderRadius: 8,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    padding: "8px 0 !important",
                  }),
                }}
                onChange={(value) => setVenueId(value?.value as string)}
              />
            </div>

            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Provider
              </label>
              <Select
                value={providerOptions.find((o) => o.value === providerFilter)}
                isClearable={true}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: DropDownIndicator,
                  IndicatorSeparator: () => null,
                  ClearIndicator: (props) => (
                    <components.ClearIndicator {...props}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </components.ClearIndicator>
                  ),
                }}
                options={providerOptions}
                isSearchable={true}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CFDBD5"
                      : "1px solid #CFDBD5",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CFDBD5"
                        : "1px solid #CFDBD5",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 40,
                    minWidth: 190,
                    borderRadius: 8,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    padding: "8px 0 !important",
                  }),
                }}
                onChange={(value) => setProviderFilter(value?.value as string)}
              />
            </div>


            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Integration Status
              </label>
              <Select
                value={integrationOptions.find((o) => o.value === integrationFilter)}
                isClearable={true}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: DropDownIndicator,
                  IndicatorSeparator: () => null,
                  ClearIndicator: (props) => (
                    <components.ClearIndicator {...props}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </components.ClearIndicator>
                  ),
                }}
                options={integrationOptions}
                isSearchable={true}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CFDBD5"
                      : "1px solid #CFDBD5",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CFDBD5"
                        : "1px solid #CFDBD5",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 40,
                    minWidth: 190,
                    borderRadius: 8,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    padding: "8px 0 !important",
                  }),
                }}
                onChange={(value) => setIntegrationFilter(value?.value as string)}
              />
            </div>

            <div className="inline-flex gap-2.5 items-center">
              <label className="flex items-center text-xs font-bold text-black md:whitespace-nowrap">
                Status
              </label>
              <Select
                value={activeOptions.find((o) => o.value === providerFilter)}
                isClearable={true}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: DropDownIndicator,
                  IndicatorSeparator: () => null,
                  ClearIndicator: (props) => (
                    <components.ClearIndicator {...props}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </components.ClearIndicator>
                  ),
                }}
                options={activeOptions}
                isSearchable={true}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CFDBD5"
                      : "1px solid #CFDBD5",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CFDBD5"
                        : "1px solid #CFDBD5",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 40,
                    minWidth: 190,
                    borderRadius: 8,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                  }),
                  clearIndicator: (styles) => ({
                    ...styles,
                    padding: "8px 0 !important",
                  }),
                }}
                onChange={(value) => setActiveFilter(value?.value as string)}
              />
            </div>
          </div>
        </div>
        <section className="flex flex-col pt-4 px-5 xl:px-[20px]">
          <section className="flex flex-col px-5 xl:px-[20px]">
            <div id="ticketMappings" className="mt-5 max-w-[calc(100vw-3rem)]">
              <Table<TicketMapping>
                nonCenteredHeading={true}
                // <table id>
                tableId="ticketMappingsTable"
                // <table className>
                tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
                // <thead className>
                tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl [&:nth-last-child(2)]:rounded-r-xl"
                // <tbody className>
                tableTbodyClassNames={`relative ${
                  page.length === 0 ? "h-[60px]" : ""
                }`}
                // Mobile <tbody className>
                tableTbodyMobileClassNames="block"
                // <tbody><tr className>
                tableTbodyTrClassNames="h-1 last-of-type:hidden"
                // Empty <tbody><tr className>
                tableTbodyTrEmptyMobileClassNames="border-none"
                // Mobile <tbody><tr className>
                tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative border-2 border-cs-off-white font-sm font-normal text-black rounded-xl p-3 bg-white"
                // <tbody><tr><td className>
                tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
                // Empty <tbody><tr><td className>
                tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
                // Mobile <tbody><tr><td className>
                tableTbodyTrEmptyTdMobileClassNames="h-2"
                // Hide Columns On Mobile
                hideColumnOnMobile={[]}
                fullWidthMobileTd={[]}
                spacedMobileTd={[]}
                // react-table props
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                page={page}
                pages={tableData?.total}
                prepareRow={prepareRow}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                setPageSize={setPageSize}
                isFetching={isFetching}
              />
            </div>
          </section>
        </section>
      </DashboardContainer>

      <Footer />
    </div>
  );
};

export default TicketMappings;

function openLinkInNewTab(url: string) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
}