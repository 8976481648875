export function TAndC() {
  return (
    <div className="pr-10 flex flex-col gap-y-5">
      <div>
        (A) The Supplier/Principal is the operator and supplier of the
        Attraction(s). The Agent carries on business as a ticket sales agent for
        attractions worldwide to customers via the Agent’s websites and mobile
        application.
      </div>

      <div>
        (B) Under this Agreement, the Supplier/Principal wishes to use the
        agency services of the Agent to sell the Attraction(s) on behalf of the
        Supplier/Principal.
      </div>

      <div>
        (C) The Parties will keep the contents of this Agreement and its terms
        of trading including agreed commission rates, strictly confidential and
        unless obliged to do so by law will not disclose them to any third
        party.
      </div>

      <div>
        (D) Under this Agreement, the Agent permits the Supplier/Principal to
        use the Ticket Manager system to sell Attraction(s) tickets.
      </div>

      <strong>1. Definitions</strong>
      <div>
        1.1 In this Agreement the following words and expressions shall have the
        following meanings:
      </div>

      <div className="pl-5 flex flex-col gap-y-5">
        <div className="flex md:flex-nowrap flex-wrap gap-x-5">
          <strong className="min-w-[180px] flex-shrink-0">
            “Attraction(s)”
          </strong>
          <div>
            means the attractions, events or tours and any other services sold
            by the Supplier/Principal and listed in Schedule 1 of this
            Agreement, or as otherwise agreed between the parties.
          </div>
        </div>

        <div className="flex md:flex-nowrap flex-wrap gap-x-5">
          <strong className="min-w-[180px] flex-shrink-0">
            "Booking Conditions"
          </strong>
          <div>
            means the Supplier/Principal’s terms and conditions governing the
            Supplier/Principal’s contract with the Customer.
          </div>
        </div>

        <div className="flex md:flex-nowrap flex-wrap gap-x-5">
          <strong className="min-w-[180px] flex-shrink-0">"Customer(s)"</strong>
          <div>
            means any party which purchases the Tickets for the Attractions from
            the Supplier/Principal through the Agent and any party for whom
            Tickets are purchased.
          </div>
        </div>

        <div className="flex md:flex-nowrap flex-wrap gap-x-5">
          <strong className="min-w-[180px] flex-shrink-0">“Gate Ready”</strong>
          <div>
            means where the Customer purchases a Ticket from the Agent, this
            voucher will be treated as a valid ticket for entry upon arrival at
            the Attraction(s) or can be redeemed on the Supplier/Principals
            website for a valid Ticket.
          </div>
        </div>

        <div className="flex md:flex-nowrap flex-wrap gap-x-5">
          <strong className="min-w-[180px] flex-shrink-0">“Onboarding”</strong>
          <div>
            means the process that all Suppliers/Principals must follow before
            being permitted to use the Ticket Manager system.
          </div>
        </div>

        <div className="flex md:flex-nowrap flex-wrap gap-x-5">
          <strong className="min-w-[180px] flex-shrink-0">“Ticket(s)”</strong>
          <div>
            means tickets/e-tickets/vouchers for entry to the Attraction.
          </div>
        </div>

        <div className="flex md:flex-nowrap flex-wrap gap-x-5">
          <strong className="min-w-[180px] flex-shrink-0">
            ”Ticket Manager”
          </strong>
          <div>
            means the Agents ticketing platform and subsequent self serve ticket
            selling of the Supplier / Principal
          </div>
        </div>
      </div>

      <div>1.2 In this Agreement:</div>
      <div className="pl-5">
        (a) the schedules form part of this Agreement and shall be construed and
        shall have the same full force and effect as if expressly set out in the
        body of this Agreement.
        <br />
        (b) a reference to a statute, statutory provision or subordinate
        legislation (“legislation”) will, except where the context otherwise
        requires, be construed as referring to such legislation as amended and
        in force from time to time and to any legislation which re-enacts or
        consolidates (with or without modification) any such legislation;
        <br />
        (c) any phrase introduced by the terms “including”, “include”, “in
        particular” or any similar expression will be construed as illustrative
        and will not limit the sense of the words preceding those terms and they
        will be deemed to be followed by the words “without limitation”;
        <br />
        (d) a reference to “writing” or “written” includes any non-transitory
        form of visible reproduction of words (including e-mail);
        <br />
        (e) the clause headings of this Agreement will not affect the
        interpretation hereof;
        <br />
        (f) a reference to persons or entities, includes a reference to natural
        persons, any body corporate, unincorporated association, trust,
        partnership or other entity or organisation;
        <br />
        (g) a reference to a person or entity, includes a reference to that
        person’s or entity’s successors or assigns;
        <br />
        (h) a reference to an agreement, includes a reference to the agreement
        as amended from time to time; and
        <br />
        (i) a reference to clauses, paragraphs and schedules are references to
        clauses, paragraphs and schedules of this Agreement, unless specified
        otherwise (and unless the context requires otherwise, a reference to the
        schedule includes the associated appendices and annexes).
      </div>

      <strong>2. TERM</strong>
      <div>
        2.1. This Agreement will commence from the date at which the Supplier /
        Principal signs or e-signs at the onboarding statements of
        understanding, and will continue in force indefinitely until terminated
        in accordance with clause 10.
      </div>

      <strong>3. APPOINTMENT</strong>
      <div>
        3.1. By this Agreement the Supplier/Principal appoints the Agent as its
        non-exclusive agent for the retail sale and promotion of the
        Attraction(s) in the United Kingdom.
        <br />
        <br />
        3.2. The Agent accepts the appointment and agrees to promote the
        Attraction(s) and sell the Tickets within the United Kingdom and perform
        the other obligations set out in this Agreement. The Agent shall also be
        permitted to appoint sub-agents for the promotion of the Attraction(s)
        and sale of the Tickets, should the Agent wish to do so.
        <br />
        <br />
        3.3. This Agreement constitutes the entire agreement between the parties
        and supersedes any prior agreement or arrangement between the parties.
      </div>

      <strong>4. THE SUPPLIER/PRINCIPAL’S OBLIGATION</strong>
      <div>
        4.1. The Supplier/Principal agrees to provide the Attraction(s) outlined
        in Schedule 1 of this Agreement at the rates outlined in Schedule 1 of
        this Agreement or as otherwise agreed in accordance with the terms and
        conditions of this Agreement.
        <br />
        <br />
        4.2 The Supplier/Principal will ensure that in operating the Attraction
        that any material reputational risk to the Agent is mitigated and that
        the Attraction has the legal and regulatory
        <br />
        <br />
        authority to operate the Attraction. This includes ensuring compliance
        with all planning, licensing, and other regulatory requirements imposed
        on it by national, local or other laws, that all national and trade laws
        are adhered to, and that all regulations and codes relating to hygiene,
        fire and general safety and security are in place.
        <br />
        <br />
        4.6. The Supplier/Principal accepts that failure to comply with clause
        4.2, will be a material breach of contract resulting in the suspension
        or revocation of all payments otherwise due under this Agreement and/or
        the termination of this Agreement without any liability of the Agent and
        without prejudice to any other agreement or contract between the
        parties.
        <br />
        <br />
        4.7 The Supplier/Principal is wholly responsible for the correct
        information displayed and set up using Ticket Manager. This includes,
        but is not limited to; intended building works, refurbishment,
        withdrawal of services/facilities, planned or unplanned close days,
        seasonality, pricing, availability, descriptions & limitations.
        <br />
        <br />
        4.8. The Supplier/Principal warrants that any and all information and/or
        descriptive matter supplied to the Agent and/or customer by the
        Supplier/Principal concerning the Attraction(s) is accurate and that if
        any such information and/or descriptive matter ceases to be accurate,
        the Supplier/Principal will inform the Agent without delay.
        <br />
        <br />
        4.9. Should the Supplier/Principal have any planned closure days
        affecting the Attractions, they must as soon as the details are known,
        update the Ticket Manager platform to prevent the sale of any Tickets on
        those days.
        <br />
        <br />
        4.10 Where the Supplier/Principal has failed to update or has
        incorrectly entered details into the Ticket Manager platform, then it is
        assumed that all tickets purchased as Gate Ready and allow Customer
        entry to the relevant Attraction.
        <br />
        <br />
        4.11 Where the Supplier/Principal has failed, as per 4.10, then the
        Agent will pass all liability and associated costs to the
        Supplier/Principal. The Supplier/Principal will handle all customer
        enquiries and complaints, including the decision to offer
        refunds/replacement tickets to the Customers affected. Should a refund
        be required, the Agent will process the refund at the request of the
        Supplier/Principal and if necessary, charge the required administrative
        fees.
        <br />
        <br />
        4.12. Should all or any part of the Attractions close or cease to be
        available due to any unforeseen circumstances, including closure part
        way through the day, the Supplier/Principal shall inform the Agent as
        soon as is possible.
        <br />
        <br />
        4.13. The Supplier/Principal shall ensure that all staff, contractors or
        third parties engaged by the Supplier/Principal have the appropriate
        training, qualification and skills required to operate the agents
        product and provide the Attraction(s).
        <br />
        <br />
        4.14. The Supplier/Principal warrants that it shall not allow any other
        agent to, and shall not itself, sell Tickets for the Attraction(s) at a
        price equal to or lower than that which the Agent is entitled to sell
        the Tickets at. The Supplier/Principal shall therefore ensure that the
        Agent shall always have the lowest rates for the Attraction(s) available
        online.
        <br />
        <br />
        4.15. The Supplier/Principal warrants that the Tickets shall include
        fast track entry where applicable.
        <br />
        <br />
        4.16. The Supplier/Principal shall allow the Agent to market the Tickets
        and the Attraction(s) as the Agent sees fit and entirely at the Agent’s
        discretion.
        <br />
        <br />
        4.17. The Supplier/Principal shall not engage in any direct marketing to
        Customer(s) using the information supplied by the Agent without the
        Agent’s express prior consent.
        <br />
        <br />
        4.18 The Supplier/Principal shall allow all customers with a valid
        ticket to enter the Attraction without issue, except when in direct
        contradiction of an Attractions T&C’s and policies.
      </div>

      <strong>5. THE AGENT’S OBLIGATIONS</strong>

      <div>
        5.1. The Agent shall:
        <div className="pl-5">
          (a) state clearly that it acts as Agent for the Supplier/Principal
          when selling Tickets;
          <br />
          (b) have freedom to promote and use its reasonable endeavours to sell
          Tickets, including but not limited to promotion via the following
          methods: social media posts, advertisements, email, display
          advertisements, PPC remarketing, partners and closed user groups;
          <br />
          (c) use reasonable care and skill when making the Tickets available to
          Customers;
          <br />
          (d) comply with all relevant laws, regulations and codes of practice
          when carrying out its obligations under this Agreement;
          <br />
          (e) provide the Supplier/Principal with daily sales reports or visitor
          reports where agreed between the parties.
        </div>
      </div>

      <strong>6. COMPLAINTS</strong>
      <div>
        6.1. Should a Customer make a complaint in respect of the Attraction(s)
        to the Agent, the Agent shall notify the Supplier/Principal of the
        complaint, as soon as reasonably possible. The Agent shall instruct the
        Supplier/Principal, at the Supplier/Principals own cost, to deal with
        the complaint and the Customer directly.
        <br />
        <br />
        6.2. Where a complaint is received directly by the Supplier/Principal,
        they agree to deal sympathetically, efficiently and politely with all
        reasonable requests and complaints made by any Customer without cost to
        the Agent.
        <br />
        <br />
        6.3. The Agent will handle any Customer or potential Customer queries
        before, during and after a sale of Tickets, including amendment, lost
        booking reference and/or anything else in respect of the Tickets. The
        Supplier/Principal shall provide all such assistance as the Agent may
        require in dealing with such enquiries and shall respond to any
        enquiries received from the Agent within 48 hours of receipt.
        <br />
        <br />
        6.4. Without prejudice to any other clause in this Agreement, the
        Supplier/Principal agrees that it shall, at its own expense, give the
        Agent such assistance and information as it requires in relation to any
        complaint or claim brought against the Agent by a Customer.
      </div>

      <strong>7. COMMISSION AND PAYMENT</strong>

      <div>
        7.1. The rates specified in Ticketing Manager and Schedule 1, and in
        relation to this Agreement include all taxes, charges, dues, fees and
        all other sums of whatever nature which relate to the Attraction(s) and
        shall apply to all Attraction(s) referred to in Ticketing Manager or
        otherwise provided by the Supplier/Principal at any time during the
        period of this Agreement.
        <br />
        <br />
        7.2. The Agent shall be entitled to commission on all Attractions booked
        under this Agreement at the commission rates specified in Ticketing
        Manager and Schedule 1 (“the <strong>Commission</strong>”). The Agent
        shall be entitled to deduct the Commission from payments received from
        Customers before paying the remaining monies to the Supplier/Principal
        in accordance with the provisions of Schedule 1 or otherwise agreed
        between the parties.
        <br />
        <br />
        7.3. Commission shall be net of VAT which shall be payable in addition.
        <br />
        <br />
        7.4. In the event of cancellation by the Supplier/Principal, the Agent
        shall be entitled to Commission at the agreed rate on any cancellation
        and/or amendment charges collected from the Customer.
        <br />
        <br />
        7.5. The Agent shall be entitled to deduct from the payment any sums
        claimed by the Agent from the Supplier/Principal under the terms of this
        Agreement or any other agreement between the parties or on any other
        basis whatsoever.
        <br />
        <br />
        7.6. In the event of any dispute in respect of any payment the Agent
        shall be entitled to withhold the sum in question until the dispute is
        settled.
        <br />
        <br />
        7.7 The agent will send the attraction an invoice within 7 days of month
        end. The Agent will pay the attraction owed monies, within 14 days of
        being invoiced. The total sales revenue must reach a minimum of £500
        within a calendar year. The Agent will hold any balance under £500 for a
        maximum of 12 months, at which point the Supplier/Principal may forfeit
        the balance.
        <br />
        <br />
        7.8 The Agent reserves the right to amend commission rates by giving the
        Supplier/Principal three (3) months written notice of its intention to
        do so.
        <br />
        <br />
        7.9 The Supplier/Principal reserves the ability to upgrade or review its
        account to alter its Commission rate. The Agent will make available the
        Commission tiers to the Supplier/Principal at all times.
      </div>

      <strong>8. INDEMNITIES</strong>

      <div>
        <div>
          8.1. Without prejudice to any other provision in this Agreement, the
          Supplier/Principal hereby agrees to indemnify the Agent for the full
          amount of all damages, expenses, losses, compensation, demands,
          actions, liabilities, fines costs (including legal costs) and/or any
          other sum of whatever nature which, for any reason whatsoever, the
          Agent incurs or pays to any Customers, employees, agents and/or
          representatives and/or any third party (including own legal costs) or
          authority (whether pursuant to any Court Order or by way of any
          settlement which the Agent, acting reasonably in all the circumstances
          including the costs, risks and time involved in fighting any claim,
          agrees to pay or otherwise), as a result directly or indirectly in
          whole or part of:
          <div className="pl-5">
            (a) any breach of any nature whatsoever by the Supplier/Principal of
            their obligations expressed or implied under this Agreement and/or
            any act(s) and/or default(s) of the Supplier/Principal and/or any
            person(s) provided or used (directly or indirectly) by the
            Supplier/Principal; or
            <br />
            (b) any failure of the Supplier/Principal to reach the standard
            agreed with the Agent to include by way of example and by no way of
            limitation, any failures or deficiency in the standard of quality of
            the Attraction(s) provided, absence or alteration to the
            Attraction(s) or lack of cleanliness, hygiene or safety for any
            reason for any period, validity of any Tickets or any other reason
            whatsoever in connection with this Agreement or use of the
            Arrangements by a third party; or
            <br />
            (c) any Customer claim received by the Agent which relates to the
            Attraction(s).
          </div>
        </div>
        <br />
        8.2. This indemnity survives termination of this Agreement for any
        reason.
      </div>

      <strong>9. INSURANCE</strong>
      <div>
        9.1. The Supplier/Principal shall take out and maintain throughout the
        period of this Agreement comprehensive liability insurance to cover all
        risks relating to the Attraction(s) and all risks arising out of the
        performance of this Agreement including (but not limited to) any
        liability for damages for the death, injury or illness of Customers,
        employees, agents, sub-contractors howsoever arising. The
        Supplier/Principal shall on demand provide a copy of all insurance
        policies and schedules to the Agent or the Agents’ representatives
        together with a copy of the receipt for the payment of the current
        premium. The Agent will not check the policy documentation for
        suitability and it remains the responsibility of the Supplier/Principal
        to ensure that they are adequately insured at all times. The
        Supplier/Principal shall forward any renewal documents to the Agent upon
        request.
      </div>

      <strong>10. TERM, TERMINATION & EFFECT OF TERMINATION</strong>
      <div>
        10.1. This Agreement will begin on the date on which both Parties have
        signed the Agreement and can be terminated at any time, by written
        notice via the Ticket Manager Platform.
        <br />
        <br />
        <div>
          10.2. Each Party may immediately, by written notice to the other
          Party, terminate this Agreement:
          <div className="pl-5">
            (a) if the other Party commits a breach of any of the terms of this
            Agreement and the breach (if capable of remedy) is not remedied
            within 14 days of receiving written notice to do so by the other
            Party;
            <br />
            (b) if the other Party ceases to be able to pay its debts in the
            ordinary course of its business or enters into an arrangement with
            its creditors;
            <br />
            (c) if the other Party goes bankrupt or into liquidation either
            compulsory or voluntary or if a receiver is appointed in respect of
            all or any of its assets;
            <br />
            (d) if a resolution is passed or an order is made for the winding up
            of the other Party;
            <br />
            (e) if any event equivalent to the above occurs in any other
            jurisdiction and affects the other Party.
          </div>
        </div>
        <br />
        10.3. If this Agreement is terminated for any reason, the termination
        shall not apply in relation to bookings made or Tickets sold before the
        effective date of termination (regardless of whether the bookings are
        due to commence after the effective date of termination) and the rights
        and obligations of the parties under this Agreement (including the
        commission provisions) in respect of such bookings shall survive the
        termination and be enforceable notwithstanding it.
        <br />
        <br />
        10.4. Termination of this Agreement shall not affect the rights and
        obligations of either Party which have accrued or are in existence prior
        to the effective date of termination.
      </div>

      <strong>11. LIABILITY</strong>
      <div>
        11.1. The Supplier/Principal accepts and acknowledges that it is the
        Supplier/Principal who is responsible for the provision of the
        Customer’s booking and that the contract for the provision of the
        Attractions in accordance with the Customer’s booking is and shall at
        all times remain between the Supplier/Principal and Customer. The Agent
        merely acts as agent for the Supplier/Principal at all times and accepts
        no liability for the provision of the Attraction(s), the validity of the
        Ticket(s) or any other liability relating to the contract between the
        Supplier/Principal and Customer.
        <br />
        <br />
        11.2. Furthermore, where the Agent is authorised to amend/vary prices,
        deal with Customer complaints and such other similar services, the Agent
        does so on the express authorisation of the Supplier/Principal in order
        to assist the Supplier/Principal as agent and to assist Customers and
        offer an excellent level of customer service. In no circumstances is
        this intended to affect or alter the Agent’s agency status or
        relationship with the Supplier/Principal.
        <br />
        <br />
        11.3. Nothing in this Agreement shall limit or exclude either Party’s
        liability to the other for death or personal injury caused by that
        Party’s negligence, fraudulent misrepresentation or fraudulent
        concealment or any other liability which cannot be limited or excluded
        by law.
      </div>

      <strong>12. CHANGE OF OWNERSHIP OR MANAGEMENT</strong>
      <div>
        12.1. In the event of any change of ownership of the business of the
        Supplier/Principal or the Attraction(s), the Supplier/Principal shall
        notify the Agent of this immediately and shall, with the Agent’s prior
        consent, transfer its obligations under this contract to the new owner.
        Should the Agent not consent to the transfer, the Agent shall be
        entitled to immediately terminate the Agreement upon giving written
        notice to the Supplier/Principal, without liability.
      </div>

      <strong>13. FORCE MAJEURE</strong>
      <div>
        13.1. Neither Party shall be liable to the other for any contractual
        default which they could not foresee or avoid. These events can include,
        but are not limited to war, threat of war, civil strife terrorist
        activity and its consequences or the threat of such activity, riot, the
        act of any government or other national or local authority including
        port or river authorities, industrial dispute, lock closure, natural or
        nuclear disaster, fire, chemical or biological disaster, pandemic and
        all similar events outside our or the supplier(s) concerned control.
        Advice from the Foreign Office to avoid or leave a particular country
        may constitute Force Majeure.
        <br />
        <br />
        13.2. If either Party is affected by Force Majeure it shall immediately
        notify the other Party in writing of the matters constituting force
        majeure and shall keep that party fully informed of their continuance
        and of any relevant change of circumstances whilst such force majeure
        continues. The Party affected by force majeure shall take all reasonable
        steps available to minimise the effects of the force majeure on the
        performance of its obligations under this contract.
      </div>

      <strong>14. DATA PROTECTION:</strong>

      <div>
        <div>
          14.1. In this clause, the following words have the following meanings:
          <div className="pl-5">
            (a) <strong>Agreed Purposes</strong>:
            <div className="pl-5">
              (i) For the Agent to be able to make and process the Customer’s
              booking;
              <br />
              (ii) For the Supplier/Principal to fulfil its contract with the
              Customer in providing the Customer with their booked Attraction;
              <br />
              (iii) For each Party to comply with its respective obligations to
              the Customer;
              <br />
              (iv) All associated administrative marketing, accounting and legal
              requirement.
            </div>
            <br />
            (b){" "}
            <strong>
              Controller, data controller, processor, data processor, data
              subject, personal data, processing and appropriate technical and
              organisation measures
            </strong>
            : as set out in the Data Protection Legislation in force at the
            time.
            <br />
            <br />
            (c) <strong>Data Protection Legislation</strong>: (i) the Data
            Protection Act 2018, (ii) the General Data Protection Regulation
            ((EU) 2016/679) (GDPR) and any national implementing laws,
            regulations and secondary legislation, for so long as the GDPR is
            effective in the UK, and (iii) any successor legislation to the Data
            Protection Act 2018 and the GDPR.
            <br />
            <br />
            (d) <strong>Permitted Recipients</strong>: The parties to this
            agreement, the employees of each Party, any third parties engaged to
            perform obligations in connection with this Agreement.
            <br />
            <br />
            <div>
              (e) <strong>Shared Personal Data</strong>: The personal data to be
              shared between the parties of this Agreement, Shared Personal Data
              shall be confined to the following categories of information
              relevant to the following categories of data subject: Customers:
              <div className="pl-5">
                (i) Name, address, email address, telephone number and any other
                contact details;
                <br />
                (ii) [Dietary requirements (which may disclose religious
                beliefs);]
                <br />
                (iii) [Health/medical/mobility requirements;]
              </div>
            </div>
          </div>
          <br />
          14.2. <strong>Shared Personal Data</strong>. This clause sets out the
          framework for the sharing of personal data between the parties as data
          controllers. Each Party acknowledges that one Party (the Data
          Discloser) will regularly disclose to the other Party (the Data
          Recipient) Shared Personal Data collected by the Data Discloser for
          the Agreed Purposes.
          <br />
          <br />
          14.3.{" "}
          <strong>
            Effect of non-compliance with Data Protection Legislation
          </strong>
          . Each Party shall comply with all the obligations imposed on a
          controller under the Data Protection Legislation, and any material
          breach of the Data Protection Legislation by one party shall, if not
          remedied within 30 days of written notice from the other Party, give
          grounds to the other party to terminate this Agreement with immediate
          effect.
          <br />
          <br />
          <div>
            14.4.{" "}
            <strong>Particular obligations relating to data sharing</strong>.
            Each party shall:
            <div className="pl-5">
              (a) ensure that it has all necessary notices and consents in place
              to enable lawful transfer of the Shared Personal Data to the
              Permitted Recipients for the Agreed Purposes;
              <br />
              (b) give full information to any data subject, whose personal data
              may be processed under this Agreement, of the nature such
              processing. This includes giving notice that, on the termination
              of this Agreement, personal data relating to them may be retained
              by or, as the case may be, transferred to one or more of the
              Permitted Recipients, their successors and assignees;
              <br />
              (c) process the Shared Personal Data only for the Agreed Purposes;
              <br />
              (d) not disclose or allow access to the Shared Personal Data to
              anyone other than the Permitted Recipients;
              <br />
              (e) ensure that all Permitted Recipients are subject to written
              contractual obligations concerning the Shared Personal Data
              (including obligations of confidentiality) which are no less
              onerous than those imposed by this Agreement.
              <br />
              (f) ensure that it has in place appropriate technical and
              organisational measures, reviewed and approved by the other party,
              to protect against unauthorised or unlawful processing or personal
              data and against accidental loss or destruction of, or damage to,
              personal data.
              <br />
              (g) not transfer any personal data received from the Data
              Discloser outside the EEA unless the transferor;
              <br />
              (h) complies with the provisions of Article 26 of the GDPR (in the
              event the third party is a joint controller) or Article 28 of the
              GDPR (in the event the third party is a data processor); and
              <br />
              (i) ensures that (i) the transfer is to a country approved by the
              European Commission as providing adequate protection pursuant to
              Article 45 GDPR; (ii) there are appropriate safeguards in place
              pursuant to Article 46 GDPR; or (iii) one of the derogations for
              the specific situations in Article 49 GDPR applies to the
              transfer.
            </div>
          </div>
          <br />
          <div>
            14.5. <strong>Mutual assistance</strong>. Each party shall assist
            the other in complying with all applicable requirements of the Data
            Protection Legislation. In particular, each party shall;
            <div className="pl-5">
              (a) consult with the other party about any notices given to data
              subjects in relation to the Shared Personal Data;
              <br />
              (b) promptly inform the other party about the receipt of any data
              subject access request;
              <br />
              (c) provide the other party with reasonable assistance in
              complying with any data subject access request;
              <br />
              (d) not disclose or release any Shared Personal Data in response
              to a data subject access request without first consulting the
              other party wherever possible;
              <br />
              (e) assist the other party, at the cost of the other party, in
              responding to any request from a data subject and in ensuring
              compliance with its obligations under the Data Protection
              Legislation with respect to security, breach notifications, impact
              assessments and consultations with supervisory authorities or
              regulators;
              <br />
              (f) notify the other party without undue delay on becoming aware
              of any breach of the Data Protection Legislation;
              <br />
              (g) at the written direction of the Data Discloser, delete or
              return Shared Personal Data to ensure that there is no lack of
              accuracy resulting from personal data transfers;
              <br />
              (h) use compatible technology for the processing of Shared
              Personal Data to ensure that there is no lack of accuracy
              resulting from personal data transfers;
              <br />
              (i) maintain complete and accurate records and information to
              demonstrate its compliance with this clause 14 and allow for
              audits by the other party or the other party’s designated auditor;
              and
              <br />
              (j) provide the other party with contact details of at least one
              employee as point of contact and responsible manager for all
              issues arising out of the Data Protection Legislation, including
              the joint training of relevant staff, the procedures to be
              followed in the event of a data security breach, and the regular
              review of the parties’ compliance with the Data Protection
              Legislation.
              <br />
            </div>
          </div>
        </div>
      </div>

      <strong>15. INTELLECTUAL PROPERTY</strong>

      <div>
        15.1. The Supplier/Principal confirms that it owns all intellectual
        property rights or otherwise has the legal right to use all information
        and material it supplies to the Agent for the purposes of this
        Agreement, including but not limited to all names, logos, text,
        photographs, videos and images of any nature.
        <br />
        <br />
        15.1. The Supplier/Principal confirms that it owns all intellectual
        property rights or otherwise has the legal right to use all information
        and material it supplies to the Agent for the purposes of this
        Agreement, including but not limited to all names, logos, text,
        photographs, videos and images of any nature.
        <br />
        <br />
        15.3. In the event of any claim being made, objection being raised or
        issue of any nature whatsoever arising in respect of any information
        and/or material supplied to the Agent by or on behalf of the
        Supplier/Principal, whether or not they are at fault, the
        Supplier/Principal agrees to indemnify and keep the Agent indemnified
        for and in respect of all liabilities, claims, expenses, costs
        (including legal costs), damages, losses and all other sums of whatever
        nature which the Agent incurs or suffers as a result.
      </div>

      <strong>16. CONFIDENTIALITY</strong>
      <div>
        16.1. The Supplier/Principal and the Agent agree to keep the terms of
        this Agreement confidential. Further, each party agrees that they will
        not seek or allow publicity to be in relation to any part of this
        Agreement without the other party’s consent.
        <br />
        <br />
        16.2. The parties agree that any and all information exchanged pursuant
        to this Agreement about the other party’s business which is not publicly
        known, including, without limitation, trade secrets, customer lists,
        sales, cost or other unpublished financial information, marketing data,
        and any other information of a party which is marked or identified as,
        or provided under circumstances indicating that it ought to be treated
        as, confidential, is confidential and is not disclosed or otherwise made
        public by the parties except where required by law.
      </div>

      <strong>17. ANTI-BRIBERY & ANTI-SLAVERY LAWS</strong>

      <div>
        17.1. The Supplier agrees, in respect of the provision of the Services,
        the Supplier shall comply (and shall procure that its employees, agents,
        subcontractors and suppliers comply) with all applicable laws, rules and
        regulations and codes of practice, including but not limited to the
        Modern Slavery Act 2015 and the Bribery Act 2010.
        <br />
        <br />
        <strong>Anti-Bribery Obligations:</strong>
        17.2. The Supplier shall not (directly or indirectly) pay, offer, give
        or promise to pay or authorise the payment of, any portion of the
        compensation or reimbursements received hereunder or any other monies or
        other things of value to an officer or employee of a government or any
        department, agency, or instrumentality or public international
        organisation; any political party or official thereof; any candidate for
        political office; any sub-contractor or supplier, or any other person at
        the suggestion, request or direction or for the benefit of any of the
        above-described persons and entities for purposes of influencing
        official actions or decisions or securing any improper advantage in
        order to obtain or retain business, or engage in acts or transactions
        otherwise in violation of any applicable anti-bribery legislation
        including but not limited to the Bribery Act 2010 and similar
        multilateral anti-bribery agreements.
        <br />
        <br />
        <strong>Anti-Slavery Obligations:</strong>
        <div>
          17.3. In performing its obligations under the Agreement, the Supplier
          shall:
          <div className="pl-5">
            (a) comply with all applicable anti-slavery and human trafficking
            laws, statutes, regulations and codes from time to time in force
            including but not limited to the Modern Slavery Act 2015; andbr{" "}
            <br />
            (b) maintain throughout the term of this Agreement its own policies
            and procedures to ensure its compliance and upon request, send
            copies of these to Day Out With The Kids; andbr <br />
            (c) not engage in any activity, practice or conduct that would
            constitute an offence under sections 1, 2 or 4, of the Modern
            Slavery Act 2015 if such activity, practice or conduct were carried
            out in the UK; andbr <br />
            (d) ensure that each of its subcontractors and suppliers shall
            comply with all applicable anti-slavery and human trafficking laws,
            statutes, regulations and codes from time to time in force including
            but not limited to the Modern Slavery Act 2015.br <br />
          </div>
        </div>
        <br />
        <div>
          17.4. The Supplier represents and warrants that at the date of this
          Agreement, neither the Supplier nor any of its officers, employees or
          other persons associated with it:
          <div className="pl-5">
            (a) has been convicted of any offence involving slavery and human
            trafficking; and <br />
            (b) to the best of its knowledge, has been or is the subject of any
            investigation, inquiry or enforcement proceedings by any
            governmental, administrative or regulatory body regarding any
            offence or alleged offence of or in connection with slavery and
            human trafficking. <br />
          </div>
        </div>
        <br />
        17.5. The Supplier shall implement due diligence procedures for its
        subcontractors and suppliers and other participants in its supply
        chains, to ensure that there is no slavery or human trafficking in its
        supply chains.
        <br />
        <br />
        17.6. The Supplier shall indemnify Day Out With The Kids against any
        losses, liabilities, damages, costs (including but not limited to legal
        fees) and expenses incurred by or awarded against, Day Out With The Kids
        as a result of any breach by the Supplier of this clause 17.
      </div>

      <strong>18. THIRD PARTY RIGHTS</strong>
      <div>
        18.1. The parties hereby exclude any rights that any other party may
        have in relation to this Agreement under the Contract (Rights of Third
        Parties) Act 1999.
      </div>

      <strong>19. ENTIRE AGREEMENT</strong>

      <div>
        19.1. This Agreement contains the entire agreement between the Parties
        and supersedes and replaces any prior written or oral agreements,
        representations or undertakings between them.
      </div>

      <strong>20. WAIVER</strong>
      <div>
        20.1. The failure by either Party to enforce at any time or for any
        period any one or more of the terms or conditions of this Agreement
        shall not be a waiver of them or of the right at any time subsequently
        to enforce all terms and conditions of this Agreement.
      </div>

      <strong>21. VARIATION</strong>
      <div>
        21.1. This Agreement may be varied only by a document signed by the duly
        authorised representatives of both of the Parties hereto.
      </div>

      <strong>22. NO PARTNERSHIP</strong>
      <div>
        22.1. Nothing in this Agreement shall constitute the creation,
        establishment or relationship of partnership, joint venture or employer
        and employee between the Parties.
      </div>
      <strong>23. JURISDICTION</strong>

      <div>
        23.1. This Agreement shall be construed, and any dispute between the
        Parties determined, under the laws of England and Wales, and the parties
        agree to submit to the exclusive jurisdiction of the Courts of England
        and Wales.
        <br />
        <br />
        23.2. If any provision in this Agreement should be held illegal or
        unenforceable by a Court having jurisdiction, such provision shall be
        modified to the extent necessary to render it enforceable without losing
        its intent, or severed from this Agreement if no such modification is
        possible, and other provisions of this Agreement shall remain in full
        force and effect.
      </div>

      <div>
        <strong className="underline">SCHEDULE 1: COMMERCIAL TERMS</strong>
        <br />
        <strong>Commissions Payable to DOWTK.</strong>
        <br />
        Rates are defined by the Attraction Listing Plan status as of the 1st of
        the upcoming month for ticket sales completed during that same month.
        For example, if an attraction is on an Enhanced Listing Plan from
        1/3/2024 until 31/7/2024, they will pay a 15% commission on ticket sales
        during that same period. If that same attraction then downgrades to a
        Standard Listing Plan from from 1/08/2024, they will pay 20% commission
        on all future ticket sales or until they change there listing plan
        again.
        <table className="my-5 w-full border border-black text-left [&_td]:border [&_td]:border-black [&_td]:p-2 [&_th]:border [&_th]:border-black [&_th]:p-2">
          <thead>
            <tr>
              <th>Partner Type</th>
              <th>Commission Rate</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>BASIC</td>
              <td>20% + VAT</td>
            </tr>
            <tr>
              <td>Standard</td>
              <td>20% + VAT</td>
            </tr>
            <tr>
              <td>Enhanced</td>
              <td>15% + VAT</td>
            </tr>

            <tr>
              <td>Premium</td>
              <td>10% + VAT</td>
            </tr>
          </tbody>
        </table>
        <strong>PAYMENT DETAILS:</strong>
        The Agent shall notify the Supplier/Principal of the final value of
        ticket sales of the Attraction(s) and send a monthly invoice for the
        commission and VAT for Tickets which have been redeemed that month and
        pay the balance to the Supplier/Principal, under the terms and
        conditions set out in the Agreement signed at Onboarding.
        <br />
        <br />
        The tables below show examples of commissions payable by partner type,
        which are for reference only.
        <br />
        <br />
        DOWTK (the <strong>“Agent”</strong> will complete the relevant checks
        during Onboarding to ensure that the correct Partner type level and
        corresponding commission rates are applied.
      </div>
    </div>
  );
}
