import Select from "react-select";
import MultiSelectCategoryOptions from "../../lib/react-select/MultiSelectCategoryOptions";

interface Props extends React.ComponentProps<Select> {
  options?: {label: string, value: string}[]
}

export default function BooleanSelect({ ...args }: Props) {
  const options = args.options || [
    {
      label: "Yes",
      value: 'yes',
    },
    {
      label: "No",
      value: 'no',
    },
  ];
  return (
    <>
    <Select
      value={options.find((o) => o.value === (args.value ? 'yes' : 'no'))}
      onChange={(value, actionMeta) => {
        if (!value) return
        args?.onChange?.(value.value === 'yes' ? true : false, actionMeta);
      }}
      options={options}
      defaultValue={null}
      isClearable={false}
      closeMenuOnSelect={false}
      menuIsOpen={true}
      hideSelectedOptions={false}
      components={{ Option: MultiSelectCategoryOptions }}
      placeholder={""}
      captureMenuScroll={false}
      styles={{
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: "#6836D1",
          borderRadius: 20,
          height: 40,
          display: "flex",
          alignItems: "center",
        }),
        multiValueRemove: () => ({
          display: "none",
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: "#FFF",
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 14,
          fontWeight: 400,
        }),
        menu: (styles) => ({
          ...styles,
          margin: 0,
          paddingLeft: 5,
          border: "0",
          position: "relative",
          overflow: "hidden",
          boxShadow: "none",
          display: "flex",
          width: "100%",
        }),
        menuList: (base) => ({
          ...base,
          marginTop: 0,
          marginBottom: 0,
          overflow: "hidden",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -10px",
        }),
        option: (base) => ({
          ...base,
          margin: "0 5px",
        }),
        noOptionsMessage: (base) => ({
          ...base,
          color: "#555",
        }),
        control: (styles, state) => ({
          ...styles,
          display: "none",
        }),
      }}
      theme={(theme) => ({
        ...theme,
        background: "none",
        colors: {
          ...theme.colors,
          neutral80: "#555",
        },
      })}
    />
    </>
  );
}
