import React, { useEffect, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
  Row,
} from "react-table";
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Table, { IconArrow } from "../../../lib/react-table/Table";
import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import Select from "react-select";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import DropDownIndicator from "../../../lib/react-select/DropDownIndicator";
import moment from "moment";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { apiSlice } from "../../../store/apiSlice";
import { AmpUserDetail } from "../../../types/user";
import { useAppSelector } from "../../../store";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Badge } from "../../../components/global/Badge";
import { Button } from "../../../components/global/Button";
import { SearchField } from "../../../components/global/SearchField";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { TableText } from "../../../components/global/TableText";
import ContextMenu from "../../../components/global/ContextMenu";
import { TableTextNormal } from "../../../components/global/TableTextNormal";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const Users: React.FC = () => {
  const windowSize = useWindowResize();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector((state: any) => state.global);

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("first_name");
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");
  const [getFilter, setFilter] = useState<
    "all" | "active" | "suspended" | "owner" | "manager" | "content_creator"
  >("all");

  const prevQuerySearch = useRef(getQuerySearchQuery);
  const { data: tableData, isFetching } = apiSlice.useGetUsersQuery({
    pageSize: getQueryPageSize,
    page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
    sortBy: getQuerySortBy,
    query: getQuerySearchQuery,
    sortDesc: getQuerySortDesc,
    filter: getFilter,
  });

  const getAttractionsList = (attractions?: any[]) => {
    return attractions ? (
      <div className="truncate">
        {attractions.map((attraction: any, i) => {
          if (i > 10) {
            return <></>;
          }
          return (
            <span
              className={`text-black font-normal text-sm mx-1 ${
                user?.role !== "ADMIN" ? "" : "w-24"
              }`}
            >
              {attraction.name}
            </span>
          );
        })}
      </div>
    ) : (
      "--"
    );
  };

  const data = React.useMemo<AmpUserDetail[]>(
    () => tableData?.results ?? ([] as AmpUserDetail[]),
    [tableData]
  );

  const userTypes: Record<string, string> = {
    OPERATOR_OWNER: "Owner",
    ATTRACTION_MANAGER: "Manager",
    ADMIN: "Admin",
    CONTENT_CREATOR: "Creator"
  };
  const columns = React.useMemo<Column<AmpUserDetail>[]>(
    () =>
      user.role === "ADMIN"
        ? [
            {
              Header: "Email",
              accessor: (data) => data.email,
              width: "0",
              id: 'email',
            },
            {
              Header: "Payment Service Customer ID",
              accessor: (data) => data?.payment_service_customer?.payment_service_customer_id,
              width: "0",
              id: 'payment_service_customer_id',
            },
            {
              Header: "Operator",
              accessor: (row) => row.operator?.title,
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <Link
                      to={{
                        pathname: `/user/${original.id}`,
                        search: createSearchParams({
                          email: original.email,
                        }).toString(),
                      }}
                      className="block w-auto text-sm font-bold text-black truncate"
                    >
                      {original?.operator?.title}              </Link>
                  </>
              );
            },
            width: "29%",
            id: "operator",
          },
          {
              Header: "Role",
              accessor: "role",
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <TableTextNormal className="min-w-[4rem] truncate w-24">
                    {userTypes[original.role!]}
                  </TableTextNormal>
                );
              },
              sortType: (a: any, b: any) => {
                if (
                  a.original.role.toString().toLowerCase() >
                  b.original.role.toString().toLowerCase()
                )
                  return 1;
                if (
                  b.original.role.toString().toLowerCase() >
                  a.original.role.toString().toLowerCase()
                )
                  return -1;
                return 0;
              },
              width: "11.62%",
              id: "role",
            },
            {
              Header: "Last Active",
              accessor: "last_logged_in",
              sortType: (a: any, b: any) => {
                const previousRow = a.original.last_logged_in;
                const nextRow = b.original.last_logged_in;

                if (previousRow < nextRow) {
                  return 1;
                } else {
                  return -1;
                }
              },
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="w-[8rem]">
                      <TableTextNormal className="w-full">
                        {original.last_logged_in
                          ? moment(original.last_logged_in).format(
                              "DD MMM YYYY"
                            )
                          : windowSize?.width! <= 1280
                          ? ""
                          : "--"}
                      </TableTextNormal>
                    </div>
                  </>
                );
              },
              width: "14.53%",
            },
            {
              Header: "First Name",
              accessor: "first_name",
              width: "13%",
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="w-[7rem]">
                      <TableTextNormal className="w-full">
                        {original.first_name}
                      </TableTextNormal>
                    </div>
                  </>
                );
              },
            },
            {
              Header: "Last Name",
              accessor: "last_name",
              width: "13%",
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="w-[7rem]">
                      <TableTextNormal className="w-full">
                        {original.last_name}
                      </TableTextNormal>
                    </div>
                  </>
                );
              },
            },
            {
              Header: "Full Name",
              accessor: (data) => `${data.first_name} ${data.last_name}`,
              width: "0%",
              id: 'full_name',
            },
            {
              Header: "Status",
              accessor: (data) => {
                return (
                  <>
                    <TableText color={data.access_restricted ? "red" : "lime"}>
                      {data.access_restricted ? "Suspended" : "Active"}
                    </TableText>
                  </>
                );
              },
              sortType: (a: any, b: any) => {
                if (
                  a.original.venue.status.toString().toLowerCase() >
                  b.original.venue.status.toString().toLowerCase()
                )
                  return 1;
                if (
                  b.original.venue.status.toString().toLowerCase() >
                  a.original.venue.status.toString().toLowerCase()
                )
                  return -1;
                return 0;
              },
              width: "11.5%",
              id: "access_restricted",
            },
            {
              Header: "",
              accessor: "id",
              disableSortBy: true,
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <ContextMenu
                    options={[
                      {
                        name: "View / Edit",
                        linkProps: {
                          to: {
                            pathname: `/user/${original.id}`,
                            search: createSearchParams({
                              email: original.email,
                            }).toString(),
                          },
                        },
                      },
                    ]}
                  />
                );
              },
              disableGlobalFilter: true,
              width: "7.35%",
            },
          ]
        : [
            {
              Header: "First Name",
              accessor: "first_name",
              width: "13%",
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="w-full">
                      <TableTextNormal className="w-full">
                        <span className="inline mr-2 text-xs font-bold text-black xl:hidden">
                          First Name:
                        </span>
                        {original.first_name}
                      </TableTextNormal>
                    </div>
                  </>
                );
              },
            },
            {
              Header: "Last Name",
              accessor: "last_name",
              width: "13%",
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="w-full">
                      <TableTextNormal className="w-full">
                        <span className="inline mr-2 text-xs font-bold text-black xl:hidden">
                          Last Name:
                        </span>

                        {original.last_name}
                      </TableTextNormal>
                    </div>
                  </>
                );
              },
            },
            {
              Header: "Full Name",
              accessor: (data) => `${data.first_name} ${data.last_name}`,
              width: "0%",
              id: 'full_name',
            },
            {
              Header: "Email",
              accessor: (data) => data.email,
              width: "20.5%",
              id: 'email',
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="w-full">
                      <TableTextNormal className="w-full truncate">
                        <span className="inline mr-2 text-xs font-bold text-black xl:hidden">
                          Email:
                        </span>

                        {original.email}
                      </TableTextNormal>
                    </div>
                  </>
                );
              },
            },
            {
              Header: "Last Login",
              accessor: "last_logged_in",
              sortType: (a: any, b: any) => {
                const previousRow = a.original.last_logged_in;
                const nextRow = b.original.last_logged_in;

                if (previousRow < nextRow) {
                  return 1;
                } else {
                  return -1;
                }
              },
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="w-full">
                      <TableTextNormal className="w-full">
                        {original.last_logged_in
                          ? moment(original.last_logged_in).format(
                              "DD MMM YYYY"
                            )
                          : windowSize?.width! <= 1280
                          ? ""
                          : "--"}
                      </TableTextNormal>
                    </div>
                  </>
                );
              },
              width: "14.5%",
            },
            {
              Header: "Status",
              accessor: 'access_restricted',
              Cell: ({ row: { original: data } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <TableText color={data.access_restricted ? "red" : "lime"}>
                      {data.access_restricted ? "Suspended" : "Active"}
                    </TableText>
                  </>
                );
              },
              sortType: (a: any, b: any) => {
                if (
                  a.original.venue.status.toString().toLowerCase() >
                  b.original.venue.status.toString().toLowerCase()
                )
                  return 1;
                if (
                  b.original.venue.status.toString().toLowerCase() >
                  a.original.venue.status.toString().toLowerCase()
                )
                  return -1;
                return 0;
              },
              width: "9%",
              id: "access_restricted",
            },
            {
              Header: "Attractions",
              accessor: "attractions",
              disableSortBy: true,
              Cell: ({ row: { original: data } }: CellProps<AmpUserDetail>) => {
                return (
                  <>
                    <div className="max-w-[15vw]">
                      {data.role === "OPERATOR_OWNER"
                        ? getAttractionsList(data.attractions_operator)
                        : data.role === "ADMIN"
                        ? getAttractionsList()
                        : getAttractionsList(data.attractions)}
                    </div>
                  </>
                );
              },
              width: "24%",
            },
            {
              Header: "",
              accessor: "id",
              disableSortBy: true,
              Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                return (
                  <ContextMenu
                    options={[
                      {
                        name: "View / Edit",
                        linkProps: {
                          to: {
                            pathname: `/user/${original.id}`,
                            search: createSearchParams({
                              email: original.email,
                            }).toString(),
                          },
                        },
                      },
                    ]}
                  />
                );
              },
              disableGlobalFilter: true,
              width: "6%",
            },
          ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<AmpUserDetail>(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: tableData?.total,
      disableSortRemove: true,
      initialState: {
        hiddenColumns: user?.role !== "ADMIN" ? ["operator", "role", 'full_name'] : ['full_name', 'email', 'payment_service_customer_id'],
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<AmpUserDetail>;

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  const handleFilterSelectChange = (e: { value: string; desc: boolean }) => {
    setSortBy([{ id: e.value, desc: e.desc ? true : false }]);
  };

  const allOptions: { value: string; label: string; desc: boolean }[] | any = [
    { value: "first_name", label: "First name (A - Z)", desc: false },
    { value: "first_name", label: "First name (Z - A)", desc: true },
    { value: "last_name", label: "Last name (A - Z)", desc: false },
    { value: "last_name", label: "Last name (Z - A)", desc: true },
    { value: "operator", label: "Operator name (Z - A)", desc: true },
    { value: "role", label: "User role", desc: true },
    { value: "access_restricted", label: "Status", desc: true },
    {
      value: "last_logged_in",
      label: "Last logged in (recent first)",
      desc: false,
    },
    {
      value: "last_logged_in",
      label: "Last logged in (recent last)",
      desc: true,
    },
  ];

  useEffect(() => {
    document.title = "Manage Users | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery]);
  useEffect(() => {
    if (!isFetching) {
      setSearchParams(
        {
          pageSize: pageSize.toString(),
          page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");
      setQuerySortBy(sortBy[0].id.toLowerCase());
      setQuerySortDesc(sortBy[0].desc!.toString());
    }
  }, [
    pageSize,
    pageIndex,
    sortBy[0].id,
    globalFilter,
    sortBy[0].desc,
    isFetching,
  ]);

  useEffect(() => {
    if (!user?.email_verified && user?.role !== "ADMIN") {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <DashboardHeaderContainer slim={true}>
        <div className="flex items-center flex-wrap gap-y-2 w-[calc(100%-28px)] xl:w-full">
          <h1 className="text-xl font-extrabold text-black">Manage Users</h1>

          {/* <Badge color="primary" className="ml-7">
            {tableData?.total ?? 0}
          </Badge> */}

          <SearchField
            parentClassName="max-w-full w-[437px] ml-auto"
            defaultValue={searchParams.get("query") ?? ""}
            onChange={handleFilterInputChange}
            placeholder="Search for an organisation, user or email"
          />

          <Link to="/user/add" className="ml-2">
            <Button>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8124 8.66683H8.63533V11.8439C8.63533 12.0124 8.56839 12.1741 8.44923 12.2932C8.33006 12.4124 8.16844 12.4793 7.99992 12.4793C7.8314 12.4793 7.66978 12.4124 7.55061 12.2932C7.43145 12.1741 7.3645 12.0124 7.3645 11.8439V8.66683H4.18742C4.0189 8.66683 3.85728 8.59988 3.73811 8.48072C3.61895 8.36156 3.552 8.19994 3.552 8.03141C3.552 7.86289 3.61895 7.70127 3.73811 7.58211C3.85728 7.46294 4.0189 7.396 4.18742 7.396H7.3645V4.21891C7.3645 4.05039 7.43145 3.88877 7.55061 3.76961C7.66978 3.65044 7.8314 3.5835 7.99992 3.5835C8.16844 3.5835 8.33006 3.65044 8.44923 3.76961C8.56839 3.88877 8.63533 4.05039 8.63533 4.21891V7.396H11.8124C11.9809 7.396 12.1426 7.46294 12.2617 7.58211C12.3809 7.70127 12.4478 7.86289 12.4478 8.03141C12.4478 8.19994 12.3809 8.36156 12.2617 8.48072C12.1426 8.59988 11.9809 8.66683 11.8124 8.66683Z"
                  fill="white"
                />
              </svg>
              Add User
            </Button>
          </Link>
        </div>
      </DashboardHeaderContainer>

      <DashboardContainer>
        <div className="px-5 xl:px-[20px] flex flex-wrap gap-y-2">
          <div className="flex flex-wrap gap-2.5">
            <Button
              className="min-w-[100px]"
              color={getFilter === "all" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setFilter("all")}
            >
              All Users
            </Button>
            <Button
              className="min-w-[100px]"
              color={getFilter === "active" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setFilter("active")}
            >
              Active
            </Button>
            {user.role === "ADMIN" && (
              <>
                <Button
                  className="min-w-[100px]"
                  color={getFilter === "suspended" ? "primary" : "outlineGray"}
                  scale={"md"}
                  onClick={() => setFilter("suspended")}
                >
                  Suspended
                </Button>
                <Button
                  className="min-w-[100px]"
                  color={getFilter === "owner" ? "primary" : "outlineGray"}
                  scale={"md"}
                  onClick={() => setFilter("owner")}
                >
                  Owner
                </Button>
                <Button
                  className="min-w-[100px]"
                  color={getFilter === "manager" ? "primary" : "outlineGray"}
                  scale={"md"}
                  onClick={() => setFilter("manager")}
                >
                  Manager
                </Button>

                <Button
                  className="min-w-[100px]"
                  color={getFilter === "content_creator" ? "primary" : "outlineGray"}
                  scale={"md"}
                  onClick={() => setFilter("content_creator")}
                >
                  Content Creator
                </Button>
              </>
            )}
          </div>

          <div className="flex justify-between w-full ml-auto xl:w-auto">
            <Button
              color="outlineGray"
              scale="md"
              className="gap-2"
              onClick={() => {
                setSortBy([
                  {
                    id: "operator",
                    desc: getQuerySortDesc === "false" ? true : false,
                  },
                ]);
              }}
            >
              <svg
                className={`${
                  getQuerySortDesc === "false" ? `rotate-180` : ""
                } transition-transform`}
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 6.95215H21M6 12.9521H18M9 18.9521H15"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              Sort A-Z
            </Button>

            <Select
              className="md:w-1/2 xl:hidden"
              defaultValue={allOptions[0]}
              components={{
                Option: SingleSelectOption,
                DropdownIndicator: DropDownIndicator,
                IndicatorSeparator: () => null,
              }}
              onChange={handleFilterSelectChange}
              options={allOptions}
              isSearchable={false}
              styles={{
                menu: (styles) => ({
                  ...styles,
                  margin: 0,
                  border: "none",
                }),
                menuList: (base, isSelected) => ({
                  ...base,
                  paddingTop: 0,
                  paddingBottom: 0,
                  background: "white",

                  "::-webkit-scrollbar": {
                    width: 14,
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#c2c2c2",
                    borderRadius: 10,
                    border: "4px solid white",
                  },
                }),
                option: (base, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...base,
                    background: "white",
                    border: "1px solid #c2c2c2",
                    color: isSelected ? "white" : "#555",
                    "&:hover": {
                      backgroundColor: "#6836D1",
                      color: isSelected || isFocused ? "white" : "#555",
                      border: isFocused ? "1px solid white" : "",
                    },
                    "&:active": { backgroundColor: "#6836D1", color: "white" },
                  };
                },
                singleValue: (base) => ({
                  ...base,
                  color: "#555",
                  fontSize: 14,
                }),
                control: (styles, state) => ({
                  ...styles,
                  cursor: "pointer",
                  outline: "none",
                  border: state.isFocused
                    ? "1px solid #CED3CF"
                    : "1px solid #CED3CF",
                  "&:hover": {
                    border: state.isFocused
                      ? "1px solid #CED3CF"
                      : "1px solid #CED3CF",
                  },
                  boxShadow: "none",
                  paddingTop: 3,
                  paddingBottom: 3,
                  margin: 0,
                  minHeight: 30,
                  borderRadius: 8,
                }),
              }}
            />
          </div>
        </div>
        <section className="flex flex-col pt-4 px-5 xl:px-[20px]">
          <div id="manageUsersTable" className="max-w-[calc(100vw-3rem)]">
            <Table<AmpUserDetail>
              // <table id>
              tableId="manageUsersTable"
              // <table className>
              tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
              // <thead className>
              tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl [&:nth-last-child(2)]:rounded-r-xl"
              // <tbody className>
              tableTbodyClassNames={`relative ${
                page.length === 0 ? "h-[60px]" : ""
              }`}
              // Mobile <tbody className>
              tableTbodyMobileClassNames="block"
              // <tbody><tr className>
              tableTbodyTrClassNames="h-1 last-of-type:hidden"
              // Empty <tbody><tr className>
              tableTbodyTrEmptyMobileClassNames="border-none"
              // Mobile <tbody><tr className>
              tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative border-2 border-cs-off-white font-sm font-normal text-black rounded-xl p-3 bg-white"
              // <tbody><tr><td className>
              tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // Empty <tbody><tr><td className>
              tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
              // Mobile <tbody><tr><td className>
              tableTbodyTrEmptyTdMobileClassNames="h-2"
              // Hide Columns On Mobile
              hideColumnOnMobile={[
                // "Last Logged In",
                // "Status",
                "Attractions",
                // "First Name",
                // "Last Name",
              ]}
              fullWidthMobileTd={["Email", "Operator"]}
              spacedMobileTd={[]}
              // react-table props
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              pages={tableData?.total}
              prepareRow={prepareRow}
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageOptions={pageOptions}
              gotoPage={gotoPage}
              setPageSize={setPageSize}
              isFetching={isFetching}
              nonCenteredHeading={true}
            />
          </div>
        </section>
      </DashboardContainer>

      <Footer />
    </div>
  );
};

export default Users;
